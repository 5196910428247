import { handleErrorWithSentry } from "@sentry/sveltekit";

import { env } from "$env/dynamic/public";
import { initSentryInBrowser } from "$lib/sentry/browser";

globalThis.CDN_BASE = env.PUBLIC_CDN_BASE;

initSentryInBrowser();

export const handleError = handleErrorWithSentry();
